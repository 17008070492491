import { createActionGroup, props } from '@ngrx/store';
import { ImporterChannel, ImporterChannel_Source, Type } from 'src/app/shared/models';

export const ImporterChannelActions = createActionGroup({
  source: 'ImporterChannel',
  events: {
    'Load ImporterChannels': props<{
      from?: any,
      userId: string,
      size?: any,
      term?: string,
      importerSource?: ImporterChannel_Source,
      importerChannelType?: Type,
    }>(),
    'Load ImporterChannels Success': props<{ importerChannels: ImporterChannel[] }>(),
    'Load ImporterChannels Failure': props<{ error: any }>(),
    'Load ImporterChannel': props<{ importerChannelId: string; userId: string }>(),
    'Load ImporterChannel Success': props<{ importerChannel: ImporterChannel }>(),
    'Load ImporterChannel Failure': props<{ error: any }>(),
    'Add ImporterChannel': props<{
      userId: string;
      importerChannel: ImporterChannel | Partial<ImporterChannel>;
    }>(),
    'Add ImporterChannel Success': props<{ importerChannel: ImporterChannel }>(),
    'Add ImporterChannel Failure': props<{ error: any }>(),
    'Remove ImporterChannel': props<{ importerChannelId: string; userId: string }>(),
    'Remove ImporterChannel Success': props<{ importerChannelId: string }>(),
    'Remove ImporterChannel Failure': props<{ error: any }>(),
    'Update ImporterChannel': props<{
      userId: string;
      importerChannelId: string;
      importerChannel: Partial<ImporterChannel>;
    }>(),
    'Update ImporterChannel Success': props<{
      userId: string;
      importerChannelId: string;
      importerChannel: Partial<ImporterChannel>;
    }>(),
    'Update ImporterChannel Failure': props<{ error: any }>(),
  },
});
