import { createFeature, createReducer, on } from '@ngrx/store';
import { ImporterChannelActions } from './import-channel.actions';
import { ImporterChannel } from 'src/app/shared/models';

export const importerChannelFeatureKey = 'import-channel';

export interface ImporterChannelState {
  importerChannels: ImporterChannel[];
  importerChannel: ImporterChannel | null;
  loading: boolean;
  error: any;
}

export const initialState: ImporterChannelState = {
  importerChannels: [],
  importerChannel: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ImporterChannelActions.loadImporterChannels,
    (state): ImporterChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.loadImporterChannelsSuccess,
    (state, { importerChannels }): ImporterChannelState => ({
      ...state,
      importerChannels: [
        ...state.importerChannels.filter(
          x =>
            !importerChannels.find(
              y =>
                x.userId &&
                x.userId === y.userId &&
                x.id === y.id
            )
        ),
        ...importerChannels,
      ],
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.loadImporterChannelsFailure,
    (state, { error }): ImporterChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterChannelActions.loadImporterChannel,
    (state): ImporterChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.loadImporterChannelSuccess,
    (state, { importerChannel }): ImporterChannelState => ({
      ...state,
      importerChannels: [...state.importerChannels.filter((item) => importerChannel.id !== item.id && importerChannel.userId !== item.userId), importerChannel],
      importerChannel: importerChannel,
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.loadImporterChannelFailure,
    (state, { error }): ImporterChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterChannelActions.addImporterChannel,
    (state): ImporterChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.addImporterChannelSuccess,
    (state, { importerChannel }): ImporterChannelState => ({
      ...state,

      importerChannels: [...state.importerChannels, importerChannel],

      loading: false,

      error: null,
    })
  ),
  on(
    ImporterChannelActions.addImporterChannelFailure,
    (state, { error }): ImporterChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterChannelActions.removeImporterChannel,
    (state): ImporterChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.removeImporterChannelSuccess,
    (state, { importerChannelId }): ImporterChannelState => ({
      ...state,
      importerChannels: state.importerChannels.filter(
        importerChannel => importerChannel.id !== importerChannelId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.removeImporterChannelFailure,
    (state, { error }): ImporterChannelState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterChannelActions.updateImporterChannel,
    (state): ImporterChannelState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.updateImporterChannelSuccess,
    (state, { importerChannel }): ImporterChannelState => ({
      ...state,
      importerChannels: state.importerChannels.map(item =>
        // item.id === importerChannel.id ? importerChannel : item
        item.id === importerChannel.id ? { ...item, ...importerChannel } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterChannelActions.updateImporterChannelFailure,
    (state, { error }): ImporterChannelState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const importerChannelFeature = createFeature({
  name: importerChannelFeatureKey,
  reducer,
});
